export const Videos = [
  {id: 1, url: 'https://www.youtube.com/embed/YguzSNLlsy4?si=5hV0j11kvJ5W6pmX'},
  {id: 2, url: 'https://www.youtube.com/embed/G1y-U9V-u3I?si=m8H70RPg0VheHTQU'},
  {id: 3, url: 'https://www.youtube.com/embed/zYkfXoxTPZE?si=PQUNmLm6ePS8ZXrR'},
  {id: 4, url: 'https://www.youtube.com/embed/0J2FTFjyGsA?si=3ftqsT9o6uCaoWg1'},
  {id: 5, url: 'https://www.youtube.com/embed/QrCR4r3PaE8?si=j6hCrQkkHa9dM1JZ'},
  {id: 6, url: 'https://www.youtube.com/embed/84pTfoySd9Y?si=Z9udVyIojjdRhczj'},
  {id: 7, url: 'https://www.youtube.com/embed/EHeKYqu5iI4?si=9rV6q-RSC3tvcjCL'},
  {id: 8, url: 'https://www.youtube.com/embed/EYSHxyfENP4?si=kkXPAVIRmbSGpd5P'},
  {id: 9, url: 'https://www.youtube.com/embed/keLirdJ5c6I?si=nNNR_SqP13HA9ePj'},
  {id: 10, url: 'https://www.youtube.com/embed/L4xSY5CTpMM?si=94VACj-frjHUiUj3'},
  {id: 11, url: 'https://www.youtube.com/embed/Vt9aM9HhdyA?si=moXkRKzf42LXm1Fp'},
  {id: 12, url: 'https://www.youtube.com/embed/_qhCswuKFZ8?si=ac2hl9OMfBVrn1Oo'},
  {id: 13, url: 'https://www.youtube.com/embed/WNnOP_KST6Y?si=zvKCiteHS56lUQgK'},
  {id: 14, url: 'https://www.youtube.com/embed/asLl1EPg1Bc?si=QYg4apQCyjdR9nKE'},
  {id: 15, url: 'https://www.youtube.com/embed/npZRCrpkBO4?si=45BHRD9xkSiBlM9r'},
  {id: 16, url: 'https://www.youtube.com/embed/5NsAKID5SQY?si=xXHtHcT45wQEZaV6'},
  {id: 17, url: 'https://www.youtube.com/embed/Iy_OTSRMc1g?si=6KfuIH7IHfu7cgE1'},
]

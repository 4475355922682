// createApi
import api from '..'

// paths
import {FACILITY} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    FACILITYs: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({baseUrl: FACILITY, endpointData: credentials.endpointData}),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data

        return result
          ? [...result.map(({id}) => ({type: 'FACILITYS', id})), {type: 'FACILITYS', id: 'LIST'}]
          : [{type: 'FACILITYS', id: 'LIST'}]
      },
    }),
    createFACILITY: builder.mutation({
      query: (credentials) => ({
        url: FACILITY + 'create-facility',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'FACILITYS', id: 'LIST'}],
    }),
    updateFACILITY: builder.mutation({
      query: (credentials) => ({
        url: `${FACILITY}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'FACILITYS', id: arg.endpointData.id}]
      },
    }),
    deleteFACILITY: builder.mutation({
      query: (credentials) => ({
        url: `${FACILITY}${credentials.endpointData.id}/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'FACILITYS', id: arg.endpointData.id}]
      },
    }),
  }),
})

export const {
  useFACILITYsQuery,
  useCreateFACILITYMutation,
  useUpdateFACILITYMutation,
  useDeleteFACILITYMutation,
} = extendedApi

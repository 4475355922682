interface Props {
  text1: any
  text2?: any
}

export const toAvatarLetters = ({text1 = '', text2 = ''}: Props): string => {
  if (typeof text1 === 'string' && text1 && typeof text2 === 'string' && text2) {
    return `${text1.charAt(0).toUpperCase()}${text2.charAt(0).toUpperCase()}`
  } else if (typeof text1 === 'string' && text1) {
    const words: string[] = text1.split(' ')
    if (words.length > 1) {
      return `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`
    } else {
      return `${words[0].charAt(0).toUpperCase()}`
    }
  } else {
    return ''
  }
}

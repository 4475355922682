//React
import React, {FC, useState, useEffect, useRef, useMemo, Fragment} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//Helper
import {KTIcon} from '../../../../_metronic/helpers'

interface ActivationCodeModalProps {
  activation_code: string
  onCloseModal: () => void
  modalVisible: boolean
}

const ActivationCodeModal: React.FC<ActivationCodeModalProps> = ({
  activation_code,
  onCloseModal,
  modalVisible,
}) => {
  const intl = useIntl()
  const [fade, setFade] = useState(false)
  const scrollDivElement = document.querySelector('#activation_code_scroll')

  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => setFade(true), 100)
    } else {
      setFade(false)
    }
  }, [modalVisible])
  return (
    <>
      <div
        className={clsx('modal fade d-block', {show: fade})}
        id='activation_code'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <div>
                <h2 className='fw-bolder'>
                  {intl.formatMessage({id: 'FORM.BUTTON.ACTIVATIONCODE'})}
                </h2>
              </div>

              {/* end::Modal title */}

              {/* begin::Close */}
              <button
                type='button'
                className='btn btn-icon btn-sm btn-active-icon-primary'
                onClick={onCloseModal}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body h-100 my-7 text-center'>
              {activation_code.split('').map((value, index) => (
                <div key={index} className='symbol symbol-50px mx-2'>
                  <div className='symbol-label fs-2 fw-bold bg-info text-inverse-info'>
                    {value}
                  </div>
                </div>
              ))}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className={clsx('modal-backdrop fade', {show: fade})} />
      {/* end::Modal Backdrop */}
    </>
  )
}

export default ActivationCodeModal

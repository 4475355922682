// createApi
import api from '..';

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs';
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater';

import {TAGS} from '../helpers/tags';

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getActivation: builder.query({
      query: credentials => ({
        url: '/get-activation/',
        method: 'GET',
      }),
    }),
    checkActivation: builder.mutation({
      query: credentials => ({
        url: '/check-activation/',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
    }),
    verifyApp: builder.mutation({
      query: credentials => ({
        url: '/verify-app/',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: TAGS,
    }),
  }),
});

export const {
  useLazyGetActivationQuery,
  useCheckActivationMutation,
  useVerifyAppMutation,
} = extendedApi;

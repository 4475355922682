// createApi
import api from '..'

// paths
import {PACKAGE} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    packages: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({baseUrl: PACKAGE, endpointData: credentials.endpointData}),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data

        return result
          ? [...result.map(({id}) => ({type: 'PACKAGES', id})), {type: 'PACKAGES', id: 'LIST'}]
          : [{type: 'PACKAGES', id: 'LIST'}]
      },
    }),
    createPackage: builder.mutation({
      query: (credentials) => ({
        url: PACKAGE + 'create-package',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'PACKAGES', id: 'LIST'},'CALENDAR'],
    }),
    updatePackage: builder.mutation({
      query: (credentials) => ({
        url: `${PACKAGE}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'PACKAGES', id: arg.endpointData.id},'CALENDAR']
      },
    }),
    deletePackage: builder.mutation({
      query: (credentials) => ({
        url: `${PACKAGE}${credentials.endpointData.id}/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'PACKAGES', id: arg.endpointData.id},'CALENDAR']
      },
    }),
  }),
})

export const {
  usePackagesQuery,
  useLazyPackagesQuery,
  useCreatePackageMutation,
  useUpdatePackageMutation,
  useDeletePackageMutation,
} = extendedApi

import React, {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {KTIcon, toAbsoluteUrl} from '../../../helpers/'
import {toAvatarLetters} from '../../../../app/helpers/AvatarHelpers'

import {Link, useLocation, useParams} from 'react-router-dom'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//Models
import {User, Package, Lecture} from '../../../../app/models'

//API
import {useLazyCustormersQuery} from '../../../../app/api/endpoints/customer'
import {useLazyStaffsQuery} from '../../../../app/api/endpoints/staff'
import {useLazyPackagesQuery} from '../../../../app/api/endpoints/package'
import {useLazyLecturesQuery} from '../../../../app/api/endpoints/lecture'

//Modals
import {FormModal as FormModalPackage} from '../../../../app/pages/scheduleLectures/memberships/header/components/formModal'
import {FormModal as FormModalLecture} from '../../../../app/pages/scheduleLectures/lectures/header/components/formModal'

//moment
import moment from 'moment'

const Search: FC = () => {
  const intl = useIntl()
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)

  const [loading, setLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('') // S

  const [customers, setCustomers] = useState<User[]>([])
  const [staffs, setStaffs] = useState<User[]>([])
  const [packages, setPackages] = useState<Package[]>([])
  const [lectures, setLectures] = useState<Lecture[]>([])

  const [modalStatePackages, setModalStatePackages] = useState<{
    modalVisible: boolean
    value: null | Package
  }>({
    modalVisible: false,
    value: null,
  })

  const [modalStateLectures, setModalStateLectures] = useState<{
    modalVisible: boolean
    value: null | Lecture
  }>({
    modalVisible: false,
    value: null,
  })

  const [requestCustomers] = useLazyCustormersQuery()
  const [requestStaffs] = useLazyStaffsQuery()
  const [requestPackages] = useLazyPackagesQuery()
  const [requestLectures] = useLazyLecturesQuery()

  useEffect(() => {
    SearchComponent.createInsance('#kt_header_search')
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value) // Input değişimlerini state'e yaz
  }

  const GET_DATA = async () => {
    setLoading(true)
    try {
      const resCustomers = await requestCustomers({
        endpointData: {
          limit: 10,
          search: searchValue,
        },
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
      setCustomers([...resCustomers?.data?.data])

      const resStaffs = await requestStaffs({
        endpointData: {
          limit: 10,
          search: searchValue,
        },
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
      setStaffs([...resStaffs?.data?.data])
      const resPackages = await requestPackages({
        endpointData: {
          limit: 10,
          search: searchValue,
        },
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
      setPackages([...resPackages?.data?.data])

      const resLectures = await requestLectures({
        endpointData: {
          limit: 10,
          search: searchValue,
        },
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      }).unwrap()
      setLectures([...resLectures?.data?.data])
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  useEffect(() => {

    console.log(searchValue)
    if (searchValue) {
      GET_DATA()
    } else {
      setCustomers([])
      setStaffs([])
      setPackages([])
      setLectures([])
    }
  }, [searchValue])

  return (
    <>
      <div
        id='kt_header_search'
        className='header-search d-flex align-items-center w-100'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-search-responsive='false'
        data-kt-menu-trigger='auto'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-start'
        data-kt-search='true'
        ref={element}
      >
        <form
          data-kt-search-element='form'
          className='w-100 position-relative mb-5 mb-lg-0'
          autoComplete='off'
        >
          <KTIcon
            iconName='magnifier'
            className='fs-2 text-lg-3 text-gray-800 position-absolute top-50 translate-middle-y ms-5'
          />
          {/*begin::Input*/}
          <input
            type='text'
            className='search-input form-control form-control-solid ps-13'
            name='search'
            placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.SEARCH'})}
            data-kt-search-element='input'
            value={searchValue} // Input state ile bağlanıyor
            onChange={handleInputChange} // Input değişimlerini yakalayan handler
          />
          {/*end::Input*/}
          {/*begin::Spinner*/}
          {loading ? (
            <span className='position-absolute top-50 end-0 translate-middle-y lh-0 me-5'>
              <span className='spinner-border h-15px w-15px align-middle text-gray-400'></span>
            </span>
          ) : null}

          {/*end::Spinner*/}
          {/*begin::Reset*/}
          {!loading && searchValue ? (
            <span
              className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-4'
              onClick={() => {
                setSearchValue('')
              }}
            >
              <KTIcon iconName='cross' className=' fs-2 text-lg-1 me-0' />
            </span>
          ) : null}

          {/*end::Reset*/}
        </form>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div ref={wrapperElement} data-kt-search-element='wrapper'>
            {customers.length > 0 ||
            staffs.length > 0 ||
            packages.length > 0 ||
            lectures.length > 0 ? (
              <div data-kt-search-element='results'>
                <div className='scroll-y mh-200px mh-lg-350px'>
                  {customers.length > 0 ? (
                    <h3
                      className='fs-5 text-muted m-0 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT.PAGE_TITLE'})}
                    </h3>
                  ) : null}

                  {customers.map((user, index) => (
                    <Link
                      key={index}
                      to={`/customer-management/customer/${user?.id}/general`}
                      className='d-flex text-dark text-hover-primary align-items-center mb-5'
                    >
                      <div className='symbol symbol-40px me-4'>
                        {user?.avatar ? (
                          <div className='symbol-label'>
                            <img
                              src={user?.avatar}
                              alt={`${user?.first_name}_${user?.last_name}`}
                              className='w-100'
                            />
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              'symbol-label fs-3',
                              `bg-light-${user?.status ? 'success' : 'danger'}`,
                              `text-${user?.status ? 'success' : 'danger'}`
                            )}
                          >
                            {`${user?.first_name?.charAt(0).toUpperCase()}${user?.last_name
                              ?.charAt(0)
                              .toUpperCase()}`}
                          </div>
                        )}
                      </div>

                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'>{`${user?.first_name} ${user?.last_name}`}</span>
                        <span className='fs-7 fw-bold text-muted'>{user?.username}</span>
                      </div>
                    </Link>
                  ))}
                  {staffs.length > 0 ? (
                    <h3
                      className='fs-5 text-muted m-0 pt-5 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'STAFF_MANAGEMENT.PAGE_TITLE'})}
                    </h3>
                  ) : null}

                  {staffs.map((user, index) => (
                    <Link
                      key={index}
                      to={`/staff-management/staff/${user?.id}/general`}
                      className='d-flex text-dark text-hover-primary align-items-center mb-5'
                    >
                      <div className='symbol symbol-40px me-4'>
                        {user?.avatar ? (
                          <div className='symbol-label'>
                            <img
                              src={user?.avatar}
                              alt={`${user?.first_name}_${user?.last_name}`}
                              className='w-100'
                            />
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              'symbol-label fs-3',
                              `bg-light-${user?.status ? 'success' : 'danger'}`,
                              `text-${user?.status ? 'success' : 'danger'}`
                            )}
                          >
                            {`${user?.first_name?.charAt(0).toUpperCase()}${user?.last_name
                              ?.charAt(0)
                              .toUpperCase()}`}
                          </div>
                        )}
                      </div>

                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'>{`${user?.first_name} ${user?.last_name}`}</span>
                        <span className='fs-7 fw-bold text-muted'>{user?.username}</span>
                      </div>
                    </Link>
                  ))}
                  {packages.length > 0 ? (
                    <h3
                      className='fs-5 text-muted m-0 pt-5 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'SCHEDULE_LECTURES.MEMBERSHİPS_TITLE'})}
                    </h3>
                  ) : null}

                  {packages.map((row, index) => (
                    <a
                      key={index}
                      href='#'
                      className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      onClick={(event) => {
                        setModalStatePackages({
                          modalVisible: true,
                          value: row,
                        })
                      }}
                    >
                      <div
                        className='symbol symbol-40px me-4 d-flex justify-content-center align-items-center'
                        style={{
                          backgroundColor: row?.color + '40',
                          overflow: 'hidden',
                          width: 40,
                          height: 40,
                          borderRadius: 5,
                        }}
                      >
                        <span
                          style={{
                            color: row?.color,
                          }}
                        >
                          {toAvatarLetters({text1: row?.name})}
                        </span>
                      </div>

                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'> {row?.name}</span>
                        <span className='fs-7 fw-bold text-muted'>
                          {row?.currency
                            ? intl.formatNumber(row?.price, {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })
                            : intl.formatNumber(row?.price, {
                                style: 'currency',
                                currency: String(row?.currency),
                              })}
                        </span>
                      </div>
                    </a>
                  ))}
                  {lectures.length > 0 ? (
                    <h3
                      className='fs-5 text-muted m-0 pt-5 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {intl.formatMessage({id: 'SCHEDULE_LECTURES.LECTURES_TITLE'})}
                    </h3>
                  ) : null}

                  {lectures.map((row, index) => (
                    <a
                      key={index}
                      href='#'
                      className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      onClick={(event) => {
                        setModalStateLectures({
                          modalVisible: true,
                          value: row,
                        })
                      }}
                    >
                      <div
                        className='symbol symbol-40px me-4 d-flex justify-content-center align-items-center'
                        style={{
                          backgroundColor: row?.color + '40',
                          overflow: 'hidden',
                          width: 40,
                          height: 40,
                          borderTopLeftRadius: 5,
                          borderEndEndRadius: 5,
                        }}
                      >
                        <span
                          style={{
                            color: row?.color,
                          }}
                        >
                          {toAvatarLetters({text1: row?.name})}
                        </span>
                      </div>

                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'> {row?.name}</span>
                        <span className='fs-7 fw-bold text-muted'>
                          {`${moment(row?.start_date).format('DD.MM.YYYY')} - ${moment(
                            row?.end_date
                          ).format('DD.MM.YYYY')}`}
                        </span>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            ) : (
              <div data-kt-search-element='empty' className='text-center'>
                <div className='pt-10 pb-10'>
                  <KTIcon iconName='search-list' className='fs-4x opacity-50' />
                </div>

                <div className='pb-15 fw-bold'>
                  <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                  <div className='text-muted fs-7'>Please try again with a different query</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalStatePackages?.modalVisible ? (
        <FormModalPackage
          type={'update'}
          value={modalStatePackages?.value}
          modalVisible={modalStatePackages?.modalVisible}
          onCloseModal={() =>
            setModalStatePackages({
              modalVisible: false,
              value: null,
            })
          }
        />
      ) : null}

      {modalStateLectures?.modalVisible ? (
        <FormModalLecture
          type={'update'}
          value={modalStateLectures?.value}
          modalVisible={modalStateLectures?.modalVisible}
          onCloseModal={() =>
            setModalStateLectures({
              modalVisible: false,
              value: null,
            })
          }
        />
      ) : null}
    </>
  )
}

export {Search}

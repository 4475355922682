export const TAGS = [
  'ACCOUNT',
  'CALENDAR',
  'COMPANY',
  'CONTRACTS',
  'CUPONS',
  'CUSTOMER',
  'CUSTOMERS',
  'MEMBERSHIPS',
  'FACILITYS',
  'FINANCIALS',
  'GATES',
  'LECTURES',
  'PACKAGES',
  'POSS',
  'REPORTS',
  'ROOMS',
  'STAFFS',
  'STAFF',
  'CERTIFICATES',
  'SUMMARY',
  'NOTIFICATION',
  'CHAT',
  'MESSAGES',
  'STOPPING_REQUESTS',
  'MASS_NOTIFICATION',
  'INVITE_FACILITY',
  'SESSION',
]

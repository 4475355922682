// createApi
import api from '..'

// paths
import {STAFF} from '../paths'

// helpers - serializeQueryArgs
import serializeQueryArgs from '../helpers/serializeQueryArgs'
import filterQueryUrlCreater from '../helpers/filterQueryUrlCreater'

export const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    staffs: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({baseUrl: STAFF, endpointData: credentials.endpointData}),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data
        return result
          ? [...result.map(({id}) => ({type: 'STAFFS', id})), {type: 'STAFFS', id: 'LIST'}]
          : [{type: 'STAFFS', id: 'LIST'}]
      },
    }),
    createStaff: builder.mutation({
      query: (credentials) => ({
        url: STAFF + 'create-staff',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'STAFFS', id: 'LIST'}],
    }),
    generalStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/general/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'GENERAL'},
        ]
      },
    }),
    profilStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'PROFIL'},
        ]
      },
    }),
    deleteStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'STAFFS', id: 'LIST'}]
      },
    }),
    updateProfilStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'PROFIL'},
          {type: 'STAFFS', id: arg.endpointData.id},
        ]
      },
    }),
    addAvatarStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/avatar/`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'PROFIL'},
          {type: 'STAFFS', id: arg.endpointData.id},
        ]
      },
    }),
    deleteAvatarStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/avatar/`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'PROFIL'},
          {type: 'STAFFS', id: arg.endpointData.id},
        ]
      },
    }),
    institutionInformationStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/institution-info/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'INSTITUTION_INFORMATION'},
        ]
      },
    }),
    updateInstitutionInformationStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/institution-info/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'INSTITUTION_INFORMATION'},
          {type: 'STAFFS', id: arg.endpointData.id},
        ]
      },
    }),
    logStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/logs/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'LOG'},
        ]
      },
    }),
    extraStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/account-statement?page=${credentials.endpointData.page}&page_size=${credentials.endpointData.limit}`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'EXTRA'},
        ]
      },
    }),
    updateExtraStaff: builder.mutation({
      query: (credentials) => ({
        url: `/staff-payment/${credentials.endpointData.id}/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'EXTRA'},
        ]
      },
    }),
    staffCertificates: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/certificate/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        const result = response?.data?.data
        return result
          ? [
              ...result.map(({id}) => ({type: 'CERTIFICATES', id})),
              {type: 'CERTIFICATES', id: 'LIST'},
            ]
          : [{type: 'CERTIFICATES', id: 'LIST'}]
      },
    }),
    createStaffCertificate: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/create-certificate/`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CERTIFICATES', id: 'LIST'}],
    }),
    updateStaffCertificate: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/certificate/${credentials.endpointData.certificateId}`,
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CERTIFICATES', id: 'LIST'}],
    }),
    deleteStaffCertificate: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/certificate/${credentials.endpointData.certificateId}`,
        method: 'DELETE',
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'CERTIFICATES', id: 'LIST'}],
    }),
    referenceStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/guests/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'REFERENCE'},
        ]
      },
    }),
    studentStaff: builder.query({
      query: (credentials) => ({
        url: filterQueryUrlCreater({baseUrl: `${STAFF}${credentials.endpointData.id}/students/`, endpointData: credentials.endpointData}),
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'STUDENTS'},
        ]
      },
    }),
    authorisationStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/authorisation/`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [
          {type: 'STAFF', id: arg.endpointData.id},
          {type: 'STAFF', id: 'AUTHORISATION'},
        ]
      },
    }),
    updateAuthorisationStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/authorisation/`,
        method: 'PUT',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: [{type: 'STAFF', id: 'AUTHORISATION'}],
    }),
    createCategory: builder.mutation({
      query: (credentials) => ({
        url: '/category/create-category',
        method: 'POST',
        body: credentials.endpointData,
      }),
      serializeQueryArgs,
    }),
    chatStaff: builder.query({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/chat`,
        method: 'GET',
      }),
      serializeQueryArgs,
      providesTags: (response, error, arg) => {
        return [{type: 'CHAT', id: `STAFF_${arg.endpointData.id}`}]
      },
    }),
    addChatStaff: builder.mutation({
      query: (credentials) => ({
        url: `${STAFF}${credentials.endpointData.id}/chat`,
        method: 'POST',
        body: credentials.endpointData.formData,
      }),
      serializeQueryArgs,
      invalidatesTags: (response, error, arg) => {
        return [{type: 'CHAT', id: `STAFF_${arg.endpointData.id}`}]
      },
    }),
  }),
})

export const {
  useStaffsQuery,
  useLazyStaffsQuery,
  useCreateStaffMutation,
  useGeneralStaffQuery,
  useProfilStaffQuery,
  useDeleteStaffMutation,
  useUpdateProfilStaffMutation,
  useAddAvatarStaffMutation,
  useDeleteAvatarStaffMutation,
  useLogStaffQuery,
  useInstitutionInformationStaffQuery,
  useUpdateInstitutionInformationStaffMutation,
  useExtraStaffQuery,
  useUpdateExtraStaffMutation,
  useStaffCertificatesQuery,
  useCreateStaffCertificateMutation,
  useUpdateStaffCertificateMutation,
  useDeleteStaffCertificateMutation,
  useReferenceStaffQuery,
  useStudentStaffQuery,
  useLazyStudentStaffQuery,
  useAuthorisationStaffQuery,
  useUpdateAuthorisationStaffMutation,
  useCreateCategoryMutation,
  useChatStaffQuery,
  useAddChatStaffMutation,
} = extendedApi

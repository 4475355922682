//React
import {FC, useEffect, useState} from 'react'

//React Router Dom
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'

//Routes
import {PrivateRoutes} from './PrivateRoutes'
import {AuthRoutes} from './AuthRoutes'

//Logout
import {Logout} from '../modules/auth'

//App
import {App} from '../App'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {setAuthState, selectCurrentUser, selectToken} from '../redux/slice/authSlice'
import {selectInitialData} from '../redux/slice/initialDataSlice'
import {setInitialData} from '../redux/slice/initialDataSlice'

//Redux ToolKit API
import {useAccountMutation} from '../api/endpoints/account'
import {useLazyMessagesQuery} from '../api/endpoints/summary'
import {useInitialdataMutation} from '../api/endpoints/initialdata'

//ThemeMode - Light - Dark - System
import {useThemeMode} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {ThemeModeComponent} from '../../_metronic/assets/ts/layout'

//react-toastify
import {ToastContainer} from 'react-toastify'

import {ErrorsPage} from '../modules/errors/ErrorsPage'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const {mode} = useThemeMode()
  const token = useSelector(selectToken)
  const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'
  const [account, {isLoading, isError, error, data}] = useAccountMutation()
  const [
    requestInitialdata,
    {isLoading: intialDataisLoading, isError: initialDataisError, data: initialdata},
  ] = useInitialdataMutation()

  const [requestMessages] = useLazyMessagesQuery()

  useEffect(() => {
    if (!intialDataisLoading && !initialDataisError && initialdata !== undefined) {
      dispatch(setInitialData(initialdata?.data))
    }
  }, [intialDataisLoading, initialDataisError, initialdata])
  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      dispatch(setAuthState({user: {...data.data.user[0], ...data.data.user_type,company_type:data.data?.company_type}, token: token}))
    } else if (!isLoading && isError) {
      dispatch(setAuthState({user: null, token: ''}))
    }
  }, [isLoading, isError, data])

  useEffect(() => {
    if (currentUser !== null) {
      account({
        endpointData: null,
        toastSuccessMessageStatus: false,
        toastErrorMessageStatus: true,
      })
    }
    requestInitialdata({
      endpointData: null,
      intl: null,
      toastSuccessMessageStatus: false,
      toastErrorMessageStatus: false,
      dispatch,
    })
  }, [])

  // useEffect(() => {
  //   let timer1

  //   if (currentUser?.in_app_messaging === true) {
  //     timer1 = setInterval(() => {
  //       try {
  //         requestMessages({
  //           endpointData: {},
  //           toastSuccessMessageStatus: false,
  //           toastErrorMessageStatus: false,
  //         })
  //       } catch (error) {}
  //     }, 5000)
  //   }

  //   return () => {
  //     clearInterval(timer1)
  //   }
  // }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthRoutes />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
      <ToastContainer
        closeButton={false}
        position={'top-right'}
        autoClose={4000}
        draggable={false}
        hideProgressBar={false}
        theme={mode === 'system' ? systemMode : mode}
      />
    </BrowserRouter>
  )
}

export {AppRoutes}

import React, {useEffect, useRef} from 'react'
import nouislider from 'nouislider'

const RangeSlider = ({min, max,initialMin,initialMax, onChange}) => {
  const sliderRef = useRef(null)

  useEffect(() => {
    // Slider'ı oluşturun
    const slider = nouislider.create(sliderRef.current, {
      start: [initialMin, initialMax],
      range: {min, max},
      //tooltips: true,
      connect: true,
      step: 1,
      format: {
        // 'to' the formatted value. Receives a number.
        to: function (value) {
            return parseInt(value);
        },
        // 'from' the formatted value.
        // Receives a string, should return a number.
        from: function (value) {
          return parseInt(value);
        }
    }
    })

    // Değer değişikliğini dinleyin
    slider.on('update', (values, handle) => {
      onChange(values[0], values[1])
    })

    // Component unmount olduğunda slider'ı temizle
    return () => {
      slider.destroy()
    }
  }, [initialMin,initialMax])

  return (
    <div style={{height:43.2}} className='form-control form-control-solid p-4'>
      <div className='me-4' ref={sliderRef} />
    </div>
  )
}

export default RangeSlider

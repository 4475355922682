/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {KTIcon} from '../../../../../_metronic/helpers'
//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

type Props = {
  className: string
  color: string
  total_amount: string | number
  overdue_debt: string | number
  due_debt: string | number
  unpaid_installment_amount: string | number
  due_installment_amount: string | number
}

const FinancialSummary: React.FC<Props> = ({
  className,
  color,
  total_amount,
  overdue_debt,
  due_debt,
  unpaid_installment_amount,
  due_installment_amount,
}) => {
  const intl = useIntl()
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>
              {intl.formatMessage({id: 'DASHBOARD.FINANCIAL_SUMMARY.TITLE'})}
            </h3>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>
              {intl.formatMessage({id: 'DASHBOARD.FINANCIAL_SUMMARY.TOTAL_AMOUNT'})}
            </span>
            <span className='fw-bold fs-2x pt-1'>
              {' '}
              {intl.formatNumber(
                parseInt(overdue_debt as string) +
                  parseInt(due_debt as string) +
                  parseInt(unpaid_installment_amount as string) +
                  parseInt(due_installment_amount as string),
                {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )}
              {/* {intl.formatNumber(parseInt(total_amount as string), {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })} */}
            </span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='compass' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {intl.formatMessage({id: 'DASHBOARD.FINANCIAL_SUMMARY.OVERDUE_DEBT'})}
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                  {intl.formatNumber(parseInt(overdue_debt as string), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='category' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {intl.formatMessage({id: 'DASHBOARD.FINANCIAL_SUMMARY.DUE_DEBT'})}
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                  {intl.formatNumber(parseInt(due_debt as string), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='phone' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {intl.formatMessage({
                    id: 'DASHBOARD.FINANCIAL_SUMMARY.UNPAID_INSTALLMENT_AMOUNT',
                  })}
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                  {intl.formatNumber(parseInt(unpaid_installment_amount as string), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {intl.formatMessage({id: 'DASHBOARD.FINANCIAL_SUMMARY.DUE_INSTALLMENT_AMOUNT'})}
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                  {intl.formatNumber(parseInt(due_installment_amount as string), {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {FinancialSummary}

//React
import React, {FC, useState, useEffect, useRef, useMemo, Fragment} from 'react'

//i18n - react-intl - internationalization - locale -localization
import {useIntl} from 'react-intl'

//CLSX - Dynamic CSS Class Creator
import clsx from 'clsx'

//Typeahead
import {Typeahead, AsyncTypeahead} from 'react-bootstrap-typeahead'

//React Hook Form - Yup Validation
import {useForm, Controller, useFieldArray} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import {dateFormat} from '../../../../../i18n/Metronici18n'

//lodash
import _ from 'lodash'

//Model
import {Lecture, Option} from '../../../../../models'

//Redux ToolKit API
import {
  useCreateLectureMutation,
  useUpdateLectureMutation,
} from '../../../../../api/endpoints/lecture'
import {useROOMsQuery} from '../../../../../api/endpoints/room'
import {useStaffsQuery} from '../../../../../api/endpoints/staff'
import {usePackagesQuery} from '../../../../../api/endpoints/package'
import {useCompanyQuery} from '../../../../../api/endpoints/company'

//Redux - Dispatch - Actions - State
import {useDispatch, useSelector} from 'react-redux'
import {selectToken, selectCurrentUser, setAuthState} from '../../../../../redux/slice/authSlice'
import {selectInitialData} from '../../../../../redux/slice/initialDataSlice'

//Helper
import {KTIcon} from '../../../../../../_metronic/helpers'

//Menu Component
import {ScrollComponent} from '../../../../../../_metronic/assets/ts/components'

//Moment
import moment from 'moment'

interface FormModalProps {
  type: 'create' | 'update'
  value: Lecture | null
  onCloseModal: () => void
  modalVisible: boolean
}

const FormModal: React.FC<FormModalProps> = ({type, value, onCloseModal, modalVisible}) => {
  const intl = useIntl()
  const [fade, setFade] = useState(false)
  const scrollDivElement = document.querySelector('#modal_create_and_update_lecture_scroll')

  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => setFade(true), 100)
    } else {
      setFade(false)
    }
  }, [modalVisible])

  useEffect(() => {
    if (fade) {
      ScrollComponent.reinitialization()
      if (scrollDivElement) {
        scrollDivElement.scrollTo(0, 0)
      }
    }
  }, [fade])

  const dispatch = useDispatch()
  const initialdata = useSelector(selectInitialData)

  const dayOfWeeks = initialdata?.day_of_weeks
  const [requestUpdate, {isLoading: updateIsLoading, isError: updateIsError, data: updateData}] =
    useUpdateLectureMutation()
  const [requestAdd, {isLoading: addIsLoading, isError: addIsError, data: addData}] =
    useCreateLectureMutation()
  const request = type === 'update' ? requestUpdate : requestAdd
  const isLoading = type === 'update' ? updateIsLoading : addIsLoading
  const isError = type === 'update' ? updateIsError : addIsError
  const data = type === 'update' ? updateData : addData
  const {data: roomData} = useROOMsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: companyInformationData} = useCompanyQuery({
    endpointData: {},
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const {data: staffData} = useStaffsQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const session_duration = companyInformationData?.data[0]?.session_duration

  const [changeDateStatus1, setChangeDateStatus1] = useState(false)
  const [changeDateStatus1Check, setChangeDateStatus1Check] = useState(false)

  const [changeDateStatus2, setChangeDateStatus2] = useState(false)
  const [changeDateStatus2Check, setChangeDateStatus2Check] = useState(false)

  const [changeDateStatus3_4, setChangeDateStatus3_4] = useState(false)
  const [changeDateStatus3_4Check, setChangeDateStatus3_4Check] = useState(false)

  const [changeDateStatus5, setChangeDateStatus5] = useState(false)
  const [changeDateStatus5Check, setChangeDateStatus5Check] = useState(false)
  const [lastWatchFormField, setLastWatchFormField] = useState(null)

  const {data: packageData} = usePackagesQuery({
    endpointData: {
      limit: -1,
    },
    toastSuccessMessageStatus: false,
    toastErrorMessageStatus: true,
  })

  const formSchema = Yup.object().shape({
    color: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    name: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    description: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    capacity: Yup.string().when('private_lesson', {
      is: (val: boolean) => val === true,
      then: (schema) => schema.required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
      otherwise: (schema) => schema.notRequired(),
    }),
    status: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    flexibility: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    private_lesson: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    clone_planning: Yup.boolean().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    start_date: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    end_date: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    packages: Yup.array()
      .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
      .min(1, intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
    lesson_plan: Yup.array()
      .of(
        Yup.object()
          .shape({
            day: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
            start: Yup.string()
              .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
              .matches(
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                intl.formatMessage({id: 'FORM.VALIDATION.INVALID_TIME_FORMAT'}) // HH:mm formatında kontrol
              ),
            end: Yup.string()
              .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
              .matches(
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                intl.formatMessage({id: 'FORM.VALIDATION.INVALID_TIME_FORMAT'}) // HH:mm formatında kontrol
              ),
            personel: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
            room: Yup.string().required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
            capacity: Yup.string()
              .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})) // Kapasite boş bırakılamaz.
              .test(
                'is-positive-integer',
                intl.formatMessage({id: 'FORM.VALIDATION.MUSTBEPOSITIVEINTEGER'}),
                // Kullanıcıya gösterilecek hata mesajı.
                (value) => {
                  // Eğer değer string olarak gelemiyorsa veya dönüşemezse false döner
                  const parsedValue = Number(value)
                  return Number.isInteger(parsedValue) && parsedValue > 0 // Pozitif tam sayı mı kontrol eder
                }
              ),
          })
          .test(
            'start-before-end',
            intl.formatMessage({id: 'FORM.VALIDATION.START_BEFORE_END'}),
            (values) => {
              if (!values.start || !values.end) return true // Eğer biri boşsa, zaten başka bir validasyon hatası dönecek
              const [startHour, startMinute] = values.start.split(':').map(Number)
              const [endHour, endMinute] = values.end.split(':').map(Number)

              // Start, end'den önce olmalı
              return startHour < endHour || (startHour === endHour && startMinute < endMinute)
            }
          )
      )
      .when('private_lesson', {
        is: (val: boolean) => val === false,
        then: (schema) =>
          schema
            .required(intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'}))
            .min(1, intl.formatMessage({id: 'FORM.VALIDATION.REQUIRED'})),
        otherwise: (schema) => schema.notRequired(),
      }),
  })

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    control,
    getValues,
    setFocus,
    formState: {errors, touchedFields, isSubmitting, isValid, isDirty},
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      color: '',
      name: '',
      description: '',
      capacity: '',
      start_date: '',
      end_date: '',
      status: true,
      flexibility: false,
      private_lesson: false,
      clone_planning: false,
      packages: [] as Option[],
      lesson_plan: [],
    },

    resolver: yupResolver(formSchema),
  })

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'lesson_plan',
  })

  const watchFormField = watch()

  useEffect(() => {
    if (
      initialdata != null &&
      modalVisible &&
      type == 'update' &&
      value != null &&
      roomData?.data?.data != undefined &&
      staffData?.data?.data != undefined &&
      packageData?.data?.data != undefined
    ) {
      reset({
        color: value.color,
        name: value.name,
        description: value.description,
        capacity: String(value.capacity),
        start_date: String(value.start_date),
        end_date: String(value.end_date),
        status: value.status,
        flexibility: value.flexibility,
        private_lesson: value.private_lesson,
        clone_planning: value.clone_planning,
        packages: value.packages
          .map((x: number) => packageData?.data?.data.filter((val) => val.id == x)[0])
          .filter((item) => item !== undefined),
        lesson_plan: Object.entries(value.lesson_plan).map(
          ([day, [lesson, startTime, endTime, personel, room, capacity]]) => {
            const start = startTime
            const end = endTime
            return {
              day: lesson,
              start,
              end,
              personel,
              room,
              capacity,
            }
          }
        ),
      })
    }
  }, [initialdata, modalVisible, type, value, roomData, staffData, packageData])

  const onSubmit = (formData: any) => {
    const sendFormdata = new FormData()

    Object.keys(formData).forEach(function (key) {
      if (key == 'capacity') {
        sendFormdata.append('capacity', formData.private_lesson == true ? formData[key] : '0')
      } else if (key == 'lesson_plan') {
        sendFormdata.append('lesson_plan', JSON.stringify(formData[key]))
      } else {
        if (typeof formData[key] === 'object') {
          formData[key].map((x: any) => {
            sendFormdata.append(key, String(x.id))
          })
        } else {
          sendFormdata.append(key, formData[key])
        }
      }
    })

    request({
      endpointData: type == 'create' ? sendFormdata : {id: value?.id, formData: sendFormdata},
      toastSuccessMessageStatus: true,
      toastErrorMessageStatus: true,
    })
  }

  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      if (type == 'create') {
        onCloseModal()
        reset()
      }
      //

      if (scrollDivElement) {
        scrollDivElement.scrollTo(0, 0)
      }
    }
  }, [isLoading, isError, data])

  useEffect(() => {
    if (type == 'update' && value != null) {
      const startDateDiff = moment(watchFormField?.start_date).diff(value?.start_date, 'days')
      const endDateDiff = moment(watchFormField?.end_date).diff(value?.end_date, 'days')

      if (!isNaN(startDateDiff) && !isNaN(endDateDiff) && startDateDiff == 0 && endDateDiff == 0) {
        setChangeDateStatus1(false)
        setChangeDateStatus1Check(false)
        setChangeDateStatus2(false)
        setChangeDateStatus2Check(false)
        setChangeDateStatus3_4(false)
        setChangeDateStatus3_4Check(false)
        setChangeDateStatus5(false)
        setChangeDateStatus5Check(false)
      } else if (
        !isNaN(startDateDiff) &&
        !isNaN(endDateDiff) &&
        startDateDiff != 0 &&
        endDateDiff != 0 &&
        changeDateStatus3_4 == false
      ) {
        console.log('Buraya giriyor')
        console.log(changeDateStatus3_4)
        setChangeDateStatus1(false)
        setChangeDateStatus1Check(false)
        setChangeDateStatus2(false)
        setChangeDateStatus2Check(false)
        setChangeDateStatus3_4(true)
        setChangeDateStatus3_4Check(false)
        setChangeDateStatus5(false)
        setChangeDateStatus5Check(false)
      } else if (endDateDiff < 0 && startDateDiff == 0 && changeDateStatus1 == false) {
        console.log('Buraya giriyor')
        console.log(changeDateStatus1)
        setChangeDateStatus1(true)
        setChangeDateStatus1Check(false)
        setChangeDateStatus2(false)
        setChangeDateStatus2Check(false)
        setChangeDateStatus3_4(false)
        setChangeDateStatus3_4Check(false)
        setChangeDateStatus5(false)
        setChangeDateStatus5Check(false)
      } else if (
        (startDateDiff > 0 || startDateDiff < 0) &&
        endDateDiff == 0 &&
        changeDateStatus2 == false
      ) {
        console.log('Buraya giriyor')
        console.log(changeDateStatus2)
        setChangeDateStatus1(false)
        setChangeDateStatus1Check(false)
        setChangeDateStatus2(true)
        setChangeDateStatus2Check(false)
        setChangeDateStatus3_4(false)
        setChangeDateStatus3_4Check(false)
        setChangeDateStatus5(false)
        setChangeDateStatus5Check(false)
      } else if (endDateDiff > 0 && startDateDiff == 0 && changeDateStatus5 == false) {
        console.log('Buraya giriyor')
        console.log(changeDateStatus5)
        setChangeDateStatus1(false)
        setChangeDateStatus1Check(false)
        setChangeDateStatus2(false)
        setChangeDateStatus2Check(false)
        setChangeDateStatus3_4(false)
        setChangeDateStatus3_4Check(false)
        setChangeDateStatus5(true)
        setChangeDateStatus5Check(false)
      }
    }
  }, [
    type,
    value,
    watchFormField,
    changeDateStatus1,
    changeDateStatus2,
    changeDateStatus3_4,
    changeDateStatus5,
  ])

  const onBlurHandler = () => {
    if (isValid && isDirty && !isLoading && type == 'update') {
      const cvalue = getValues()
      onSubmit(cvalue)
    }
  }
  const onBlurHandlerDirtByPass = () => {
    if (isValid && type == 'update' && !isLoading) {
      const cvalue = getValues()
      onSubmit(cvalue)
    }
  }

  const updateEndTime = (index: number) => {
    setTimeout(() => {
      if (watchFormField.lesson_plan && watchFormField.lesson_plan[index]?.start) {
        const calcEndTime = moment(watchFormField.lesson_plan[index].start, 'HH:mm')
          .add(session_duration, 'minutes')
          .format('HH:mm')

        setValue(`lesson_plan.${index}.end` as const, calcEndTime)
        setFocus(`lesson_plan.${index}.end`)
      }
    }, 500)
  }

  return (
    <>
      <div
        className={clsx('modal fade d-block', {show: fade})}
        id='modal_create_and_update_lecture'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>
                {type === 'create'
                  ? intl.formatMessage({id: 'SCHEDULE_LECTURES.NEW_LECTURE_TITLE'})
                  : intl.formatMessage({id: 'SCHEDULE_LECTURES.UPDATE_LECTURE_TITLE'})}
              </h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <button
                type='button'
                className='btn btn-icon btn-sm btn-active-icon-primary'
                onClick={onCloseModal}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 my-7'>
              <form
                id='modal_create_and_update_lecture_form'
                className='form'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7 px-2'
                  id='modal_create_and_update_lecture_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#modal_create_and_update_lecture_header'
                  data-kt-scroll-wrappers='#modal_create_and_update_lecture_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                      {intl.formatMessage({id: 'FORM.LABEL.LECTURE_NAME'})}
                    </label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      type='text'
                      {...register('name')}
                      placeholder={''}
                      className={clsx(
                        'form-control form-control-solid  mb-3 mb-lg-0',
                        {'is-invalid': touchedFields?.name && errors?.name},
                        {
                          'is-valid': touchedFields?.name && !errors?.name,
                        }
                      )}
                      onBlur={onBlurHandler}
                    />
                    {errors?.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.name?.message}</span>
                        </div>
                      </div>
                    )}

                    {/* end::Input */}
                  </div>
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                      {intl.formatMessage({id: 'FORM.LABEL.DESCRIPTION'})}
                    </label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <textarea
                      rows={2}
                      {...register('description')}
                      placeholder={''}
                      className={clsx(
                        'form-control form-control-solid  mb-3 mb-lg-0',
                        {'is-invalid': touchedFields?.description && errors?.description},
                        {
                          'is-valid': touchedFields?.description && !errors?.description,
                        }
                      )}
                      onBlur={onBlurHandler}
                    />
                    {errors?.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.description?.message}</span>
                        </div>
                      </div>
                    )}

                    {/* end::Input */}
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}

                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.COLOR'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          style={{
                            height: 43.57,
                          }}
                          type='color'
                          {...register('color')}
                          placeholder={''}
                          className={clsx(
                            'form-select form-select-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.color && errors?.color},
                            {
                              'is-valid': touchedFields?.color && !errors?.color,
                            }
                          )}
                          onBlur={onBlurHandler}
                        />
                        {errors?.color && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.color?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    {watchFormField.private_lesson ? (
                      <div className='col-6'>
                        <div className='fv-row mb-7'>
                          {/* begin::Label */}
                          <label
                            className='required fw-bold fs-6 mb-2 w-100'
                            style={{textAlign: 'left'}}
                          >
                            {intl.formatMessage({id: 'FORM.LABEL.CAPACITY'})}
                          </label>
                          {/* end::Label */}

                          {/* begin::Input */}
                          <input
                            type='text'
                            {...register('capacity')}
                            placeholder={''}
                            className={clsx(
                              'form-control form-control-solid  mb-3 mb-lg-0',
                              {'is-invalid': touchedFields?.capacity && errors?.capacity},
                              {
                                'is-valid': touchedFields?.capacity && !errors?.capacity,
                              }
                            )}
                            onBlur={onBlurHandler}
                            disabled={watchFormField.private_lesson == true ? false : true}
                          />
                          {errors?.capacity && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors?.capacity?.message}</span>
                              </div>
                            </div>
                          )}

                          {/* end::Input */}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className='row'>
                    <div className='col-6'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.START_DATE'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='date'
                          {...register('start_date')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.start_date && errors?.start_date},
                            {
                              'is-valid': touchedFields?.start_date && !errors?.start_date,
                            }
                          )}
                          onBlur={onBlurHandler}
                        />
                        {errors?.start_date && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.start_date?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.END_DATE'})}
                        </label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                          type='date'
                          {...register('end_date')}
                          placeholder={''}
                          className={clsx(
                            'form-control form-control-solid  mb-3 mb-lg-0',
                            {'is-invalid': touchedFields?.end_date && errors?.end_date},
                            {
                              'is-valid': touchedFields?.end_date && !errors?.end_date,
                            }
                          )}
                          onBlur={onBlurHandler}
                        />
                        {errors?.end_date && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.end_date?.message}</span>
                            </div>
                          </div>
                        )}

                        {/* end::Input */}
                      </div>
                    </div>
                  </div>
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2 w-100' style={{textAlign: 'left'}}>
                      {intl.formatMessage({id: 'FORM.LABEL.MEMBERSHIP'})}
                    </label>
                    {/* end::Label */}
                    <Controller
                      control={control}
                      name='packages'
                      render={({field: {onChange, onBlur, value, ref, name}}) => (
                        <Typeahead
                          id='modal_create_and_update_lecture_packages'
                          clearButton
                          multiple
                          labelKey={'name'}
                          isValid={
                            touchedFields[name] != undefined &&
                            touchedFields[name] &&
                            (errors[name] == undefined || !errors[name])
                          }
                          isInvalid={touchedFields[name] != undefined && errors[name] != undefined}
                          instanceRef={ref}
                          options={
                            packageData?.data?.data == undefined
                              ? []
                              : packageData?.data?.data
                                  .filter((x) => x?.is_relational === false)
                                  .filter((x) => x?.status === true)
                          }
                          onChange={onChange}
                          onBlur={() => {
                            onBlur()
                            onBlurHandler()
                          }}
                          selected={value as Option[]}
                          emptyLabel={intl.formatMessage({id: 'FORM.WARNING.EMPTY_LABEL'})}
                        />
                      )}
                    />

                    {errors?.packages && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.packages?.message}</span>
                        </div>
                      </div>
                    )}

                    {/* end::Input */}
                  </div>
                  <div className='row'>
                    <div className='col-2'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('status')}
                            defaultChecked={watchFormField?.status}
                            onChange={() => {
                              setValue('status', !watchFormField?.status)
                              setTimeout(onBlurHandlerDirtByPass, 300)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.STATUS'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('flexibility')}
                            defaultChecked={watchFormField?.flexibility}
                            onChange={() => {
                              setValue('flexibility', !watchFormField?.flexibility)
                              setTimeout(onBlurHandlerDirtByPass, 300)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.FLEXIBILITY'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('private_lesson')}
                            defaultChecked={watchFormField?.private_lesson}
                            onChange={() => {
                              setValue('private_lesson', !watchFormField?.private_lesson)
                              setTimeout(onBlurHandlerDirtByPass, 300)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.PRIVATE_LESSON'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='fv-row mb-7'>
                        <label className='form-check form-check-custom  align-items-start'>
                          <input
                            className='form-check-input me-1'
                            type='checkbox'
                            {...register('clone_planning')}
                            defaultChecked={watchFormField?.clone_planning}
                            onChange={() => {
                              setValue('clone_planning', !watchFormField?.clone_planning)
                              setTimeout(onBlurHandlerDirtByPass, 300)
                            }}
                          />

                          <span className='form-check-label d-flex flex-column align-items-start me-6'>
                            <span className='fw-bolder fs-5 mb-0'>
                              {intl.formatMessage({id: 'FORM.LABEL.CLONE_PLANNING'})}
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='separator separator-dashed mb-7 ' />
                  {watchFormField?.private_lesson === true ? null : (
                    <>
                      <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label
                          className='required fw-bold fs-6 mb-2 w-100'
                          style={{textAlign: 'left'}}
                        >
                          {intl.formatMessage({id: 'FORM.LABEL.LESSON_PLAN'})}
                        </label>
                        {/* end::Label */}

                        {errors?.lesson_plan && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors?.lesson_plan?.message}</span>
                            </div>
                          </div>
                        )}

                        {fields.map((field, index) => {
                          return (
                            <Fragment key={field.id}>
                              <div className='input-group input-group-sm mb-2'>
                                <span className='input-group-text'>
                                  <KTIcon iconName='calendar-edit' className='fs-3 text-primary' />
                                </span>
                                <select
                                  id={`lesson_plan.${index}.day`}
                                  {...register(`lesson_plan.${index}.day`)}
                                  className={clsx('form-select', {
                                    'is-invalid': errors?.lesson_plan?.[index]?.day,
                                  })}
                                  onBlur={onBlurHandler}
                                  disabled={isLoading}
                                >
                                  <option value=''>
                                    {intl.formatMessage({id: 'FORM.LABEL.DAY'})}
                                  </option>
                                  {dayOfWeeks &&
                                    dayOfWeeks.map((opt: any, index: number) => (
                                      <option key={index} value={opt.id}>
                                        {`${opt?.name}`}
                                      </option>
                                    ))}
                                </select>

                                <span className='input-group-text'>
                                  <KTIcon iconName='time' className='fs-3 text-primary' />
                                </span>
                                <input
                                  type='time'
                                  id={`lesson_plan.${index}.start`}
                                  {...register(`lesson_plan.${index}.start`)}
                                  placeholder={''}
                                  className={clsx('form-control', {
                                    'is-invalid': errors?.lesson_plan?.[index]?.start,
                                  })}
                                  onBlur={() => {
                                    updateEndTime(index)
                                  }}
                                  disabled={isLoading}
                                />

                                <span className='input-group-text'>
                                  <KTIcon iconName='watch' className='fs-3 text-primary' />
                                </span>
                                <input
                                  type='time'
                                  id={`lesson_plan.${index}.end`}
                                  {...register(`lesson_plan.${index}.end`)}
                                  placeholder={''}
                                  className={clsx('form-control', {
                                    'is-invalid': errors?.lesson_plan?.[index]?.end,
                                  })}
                                  onBlur={() => {
                                    console.log('Çalıştı')
                                    setTimeout(() => {
                                      onBlurHandler()
                                    }, 1000)
                                  }}
                                  disabled={isLoading}
                                />

                                <span className='input-group-text'>
                                  <KTIcon iconName='user-tick' className='fs-3 text-primary' />
                                </span>

                                <select
                                  {...register(`lesson_plan.${index}.personel`)}
                                  id={`lesson_plan.${index}.personel`}
                                  className={clsx('form-select', {
                                    'is-invalid': errors?.lesson_plan?.[index]?.personel,
                                  })}
                                  onBlur={onBlurHandler}
                                  disabled={isLoading}
                                >
                                  <option value=''>
                                    {intl.formatMessage({id: 'FORM.LABEL.STAFF'})}
                                  </option>
                                  {staffData &&
                                    staffData?.data?.data &&
                                    staffData?.data?.data
                                      .filter((x) => x.role.indexOf('1') !== -1)
                                      .filter((x) => x.status)
                                      .map((opt: any, index: number) => (
                                        <option key={index} value={String(opt.id)}>
                                          {`${opt?.first_name} ${opt?.last_name}`}
                                        </option>
                                      ))}
                                </select>
                                <span className='input-group-text'>
                                  <KTIcon
                                    iconName='geolocation-home'
                                    className='fs-3 text-primary'
                                  />
                                </span>

                                <select
                                  {...register(`lesson_plan.${index}.room`)}
                                  id={`lesson_plan.${index}.room`}
                                  className={clsx('form-select', {
                                    'is-invalid': errors?.lesson_plan?.[index]?.room,
                                  })}
                                  onBlur={onBlurHandler}
                                  disabled={isLoading}
                                >
                                  <option value=''>
                                    {intl.formatMessage({id: 'FORM.LABEL.ROOM'})}
                                  </option>
                                  {roomData &&
                                    roomData?.data?.data &&
                                    roomData?.data?.data.map((opt: any, index: number) => (
                                      <option key={index} value={String(opt.id)}>
                                        {opt.name}
                                      </option>
                                    ))}
                                </select>

                                <span className='input-group-text'>
                                  <KTIcon iconName='people' className='fs-3 text-primary' />
                                </span>
                                <input
                                  type='number'
                                  id={`lesson_plan.${index}.capacity`}
                                  {...register(`lesson_plan.${index}.capacity`)}
                                  placeholder={'Kapasite'}
                                  className={clsx('form-control', {
                                    'is-invalid': errors?.lesson_plan?.[index]?.capacity,
                                  })}
                                  disabled={isLoading}
                                  onBlur={onBlurHandler}
                                />

                                <span
                                  className='input-group-text'
                                  onClick={() => {
                                    if (!isLoading) {
                                      remove(index)
                                      setTimeout(onBlurHandlerDirtByPass, 300)
                                    }
                                  }}
                                >
                                  <KTIcon iconName='cross' className='fs-3 text-danger' />
                                </span>
                              </div>
                              {errors?.lesson_plan?.[index]?.capacity && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>
                                      {errors?.lesson_plan?.[index]?.capacity?.message}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {errors?.lesson_plan && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{errors?.lesson_plan[index]?.message}</span>
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          )
                        })}

                        {/* end::Input */}
                      </div>
                      <button
                        type='button'
                        className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                        onClick={() => {
                          const lastLesson = watchFormField?.lesson_plan
                            ? watchFormField?.lesson_plan?.[watchFormField?.lesson_plan?.length - 1]
                            : null
                          if (lastLesson) {
                            append({
                              day: '',
                              start: lastLesson.start,
                              end: lastLesson.end,
                              personel: lastLesson.personel,
                              room: lastLesson.room,
                              capacity: '',
                            })
                          } else {
                            append({
                              day: '',
                              start: '',
                              end: '',
                              personel: '',
                              room: '',
                              capacity: '',
                            })
                          }
                        }}
                        disabled={isLoading}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        {intl.formatMessage({id: 'FORM.BUTTON.ADD_LECTURE_PLAN'})}
                      </button>
                    </>
                  )}
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                {changeDateStatus1 ? (
                  <div className='form-check form-check-solid mt-4 text-start'>
                    <label>
                      <input
                        className='form-check-input mb-4 '
                        type='checkbox'
                        checked={changeDateStatus1Check}
                        onChange={() => setChangeDateStatus1Check(!changeDateStatus1Check)}
                      />
                      <strong className='text-danger'>
                        {intl.formatMessage({id: 'FORM.WARNING.CHANGE_DATESTATUS_3_4'})}
                      </strong>
                    </label>
                  </div>
                ) : null}

                {changeDateStatus2 ? (
                  <div className='form-check form-check-solid mt-4 text-start'>
                    <label>
                      <input
                        className='form-check-input mb-4 '
                        type='checkbox'
                        checked={changeDateStatus2Check}
                        onChange={() => setChangeDateStatus2Check(!changeDateStatus2Check)}
                      />
                      <strong className='text-danger'>
                        {intl.formatMessage({id: 'FORM.WARNING.CHANGE_DATESTATUS_3_4'})}
                      </strong>
                    </label>
                  </div>
                ) : null}
                {changeDateStatus3_4 ? (
                  <div className='form-check form-check-solid mt-4 text-start'>
                    <label>
                      <input
                        className='form-check-input mb-4 '
                        type='checkbox'
                        checked={changeDateStatus3_4Check}
                        onChange={() => setChangeDateStatus3_4Check(!changeDateStatus3_4Check)}
                      />
                      <strong className='text-danger'>
                        {intl.formatMessage({id: 'FORM.WARNING.CHANGE_DATESTATUS_3_4'})}
                      </strong>
                    </label>
                  </div>
                ) : null}

                {changeDateStatus5 ? (
                  <div className='form-check form-check-solid mt-4 text-start'>
                    <label>
                      <input
                        className='form-check-input mb-4 '
                        type='checkbox'
                        checked={changeDateStatus5Check}
                        onChange={() => setChangeDateStatus5Check(!changeDateStatus5Check)}
                      />
                      <strong className='text-danger'>
                        {intl.formatMessage({id: 'FORM.WARNING.CHANGE_DATESTATUS_3_4'})}
                      </strong>
                    </label>
                  </div>
                ) : null}

                <div className='text-center pt-15'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={
                      !isDirty
                        ? true
                        : isLoading
                        ? true
                        : changeDateStatus1 && !changeDateStatus1Check
                        ? true
                        : changeDateStatus2 && !changeDateStatus2Check
                        ? true
                        : changeDateStatus3_4 && !changeDateStatus3_4Check
                        ? true
                        : changeDateStatus5 && !changeDateStatus5Check
                        ? true
                        : false
                    }
                  >
                    <span className='indicator-label'>
                      {type === 'create'
                        ? intl.formatMessage({id: 'FORM.BUTTON.SAVE'})
                        : intl.formatMessage({id: 'FORM.BUTTON.UPDATE'})}
                    </span>
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'MESSAGE.PLEASE_WAITING'})}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className={clsx('modal-backdrop fade', {show: fade})} />
      {/* end::Modal Backdrop */}
    </>
  )
}

export {FormModal}
